
import { computed, defineComponent, reactive, ref } from 'vue'
import { FolderOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue'
import type { UnwrapRef } from 'vue'

export default defineComponent({
  components: {
    FolderOutlined,
    LeftCircleOutlined,
    RightCircleOutlined
  },
  setup () {
    const formRef = ref()
    const formState = reactive({
      type: 'four',
      shellWidth: undefined,
      shellHeight: undefined,
      shellWeight: undefined,
      shellPrice: undefined,
      bottomPrice: undefined,
      shellArea: 0.00,
      bottomArea: 0.00,
      shellMoney: 0.00,
      bottomMoney: 0.00,
      total: 0.00
    })

    async function calc () {
      formRef.value.validate().then(() => {
        const type = formState.type
        let totalShell = 0.00
        let totalBottom = 0.00
        let shellArea = 0.00
        let sum = 0.00
        const width = formState.shellWidth!
        const height = formState.shellHeight!
        const weight = formState.shellWeight!
        const shellPrice = formState.shellPrice!
        const bottomPrice = formState.bottomPrice!
        if (type === 'four') {
          shellArea = Number(((width + weight) * height * 2).toFixed(2))
          totalShell = Number((shellArea * shellPrice).toFixed(2))
          sum = totalShell
        } else if (type === 'five') {
          shellArea = Number(((width + weight) * height * 2 + (width * weight)).toFixed(2))
          totalShell =
            Number((shellArea * shellPrice).toFixed(2))
          sum = totalShell
        } else if (type === 'six') {
          shellArea = Number(((width + weight) * height * 2 + (width * weight) * 2).toFixed(2))
          totalShell =
            Number((shellArea * shellPrice).toFixed(2))
          sum = totalShell
        } else if (type === 'withBottom') {
          totalBottom = Number((width * weight * bottomPrice).toFixed(2))
          shellArea = Number((((width + weight) * height * 2 + (width * weight))).toFixed(2))
          totalShell =
            Number((shellArea * shellPrice).toFixed(2))
          sum = totalBottom + totalShell
        } else if (type === 'bottom') {
          totalBottom = Number((width * weight * bottomPrice).toFixed(2))
        }
        formState.shellArea = shellArea
        formState.bottomArea = width * height
        formState.shellMoney = totalShell
        formState.bottomMoney = totalBottom
        formState.total = sum
      })
    }

    function reset () {
      Object.assign(formState, {
        type: 'four',
        shellWidth: undefined,
        shellHeight: undefined,
        shellWeight: undefined,
        shellPrice: undefined,
        bottomPrice: undefined,
        shellArea: 0.00,
        bottomArea: 0.00,
        shellMoney: 0.00,
        bottomMoney: 0.00,
        total: 0.00
      })
    }

    return {
      formRef,
      formState,
      calc,
      reset
    }
  }
})
