import { createApp } from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue'
import 'ant-design-vue/dist/antd.css'
import router from './router'
import axios from 'axios'
import VueAxios from 'vue-axios'
import { bus } from './utils/Mitt'

declare module 'vue' {
  export interface ComponentCustomProperties {
    $Bus: typeof bus
  }
}

const app = createApp(App)
// Vue3挂载全局API
app.config.globalProperties.$Bus = bus
app.use(Antd)
app.use(VueAxios, axios)
app.use(router).mount('#app')
