
import { defineComponent, ref, reactive } from 'vue'
import { LeftCircleOutlined, RightCircleOutlined, FolderOutlined } from '@ant-design/icons-vue'
import { bus } from '../utils/Mitt'
import Api from '@/utils/Api'

const banners = ref([])
const productId = ref<string>('')
const product = reactive({
  info: {
    name: '',
    picUrl: '',
    categoryName: ''
  }
})
export default defineComponent({
  components: {
    FolderOutlined,
    LeftCircleOutlined,
    RightCircleOutlined
  },
  mounted () {
    productId.value = this.$route.query.id + ''
    bus.emit('detail')
    loadData(productId.value)
  },
  setup () {
    return {
      product,
      banners,
      logo: require('../assets/logo.png')
    }
  }
})

async function loadData (id: any) {
  const response = await Api.get('/wx/goods/detail?id=' + id)
  Object.assign(product, response.data)
  banners.value = response.data.info.gallery
  console.log(product)
}

loadData(productId.value)
