
import { defineComponent, ref, reactive } from 'vue'
import { FolderOutlined, LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons-vue'

const certs = ref([
  require('../assets/img/cert1.png'),
  require('../assets/img/cert2.png'),
  require('../assets/img/cert3.png'),
  require('../assets/img/cert4.png'),
  require('../assets/img/cert5.png'),
  require('../assets/img/cert6.png'),
  require('../assets/img/cert7.png')
])

const cars = ref([
  require('../assets/img/car1.png'),
  require('../assets/img/car2.png'),
  require('../assets/img/car3.png')
])

const devices = ref([
  require('../assets/img/device1.png'),
  require('../assets/img/device2.png'),
  require('../assets/img/device3.png'),
  require('../assets/img/device4.png'),
  require('../assets/img/device5.png'),
  require('../assets/img/device6.png'),
  require('../assets/img/device7.png')
])

export default defineComponent({
    components: {
      FolderOutlined,
      LeftCircleOutlined,
      RightCircleOutlined
    },
    setup () {
      return {
        certs,
        cars,
        devices,
        companyImage: require('../assets/img/company.png')
      }
    }
  }
)

