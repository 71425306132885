
import { defineComponent, ref } from 'vue'
import { LeftCircleOutlined, RightCircleOutlined, FolderOutlined } from '@ant-design/icons-vue'
import Api from '@/utils/Api'
import router from '@/router'

const selectedKeys = ref<string[]>(['6'])
const banners = ref([
  require('../assets/banner01.png'),
  require('../assets/banner02.png'),
  require('../assets/banner03.png'),
  require('../assets/banner04.png'),
  require('../assets/banner05.png')
])
const categoryList = ref<any>([])
const cateProducts = ref<any>([])

export default defineComponent({
  components: {
    FolderOutlined,
    LeftCircleOutlined,
    RightCircleOutlined
  },
  setup () {
    return {
      banners,
      selectedKeys,
      onMenuSelect,
      categoryList,
      cateProducts,
      loadCateProducts,
      productClick
    }
  },
  created () {
    loadData()
  }
})

function onMenuSelect (item: any) {
  console.log(item.key)
  loadCateProducts(item.key)
}

async function loadCateProducts (cateId: string) {
  const response = await Api.get('/wx/goods/queryByCategory?id=' + cateId)
  const list = response.data.list
  const rowList = []
  const rowCount = 3
  let rows = parseInt(list.length / rowCount + '')
  if (list.length % rowCount > 0) {
    rows++
  }
  if (rows > 0) {
    for (let i = 0; i < rows; i++) {
      const rowData = []
      let rowMaxIndex = (i + 1) * rowCount
      if (rowMaxIndex > list.length) {
        rowMaxIndex = list.length
      }
      for (let j = i * rowCount; j < rowMaxIndex; j++) {
        rowData.push(list[j])
      }
      if (rowData.length < rowCount) {
        const lossCount = rowCount - rowData.length
        for (let j = 0; j < lossCount; j++) {
          rowData.push({
            id: -1,
            picUrl: ['']
          })
        }
      }
      rowList.push(rowData)
    }
  }
  cateProducts.value = rowList
}

function productClick (id: any) {
  const routeData = router.resolve({
    // 跳转目标窗口的地址
    path: '/product/productDetail',
    query: {
      id: id
    }
  })
  router.push(routeData)
  // window.open(href, '_blank')
}

async function loadData () {
  // console.log(process.env.VUE_APP_BASE_API)
  const response = await Api.get('/admin/category/get2LCategory')
  categoryList.value = response.data
  const first = categoryList.value[0].id
  selectedKeys.value = [first]
  await loadCateProducts(first)
}
