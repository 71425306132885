
import type { SelectProps } from 'ant-design-vue'
import { defineComponent, reactive, ref } from 'vue'

const protectGradeOptions = ref<SelectProps['options']>([
  {
    value: 'IP20',
    label: 'IP20'
  },
  {
    value: 'IP21',
    label: 'IP21'
  },
  {
    value: 'IP30',
    label: 'IP30'
  },
  {
    value: 'IP33',
    label: 'IP33'
  },
  {
    value: 'IP40',
    label: 'IP40'
  }
])

const materialOptions = ref<SelectProps['options']>([
  {
    value: '铝合金',
    label: '铝合金'
  },
  {
    value: '不锈钢',
    label: '不锈钢'
  },
  {
    value: '钢板喷塑',
    label: '钢板喷塑'
  }
])

const holeTypeOptions = ref<SelectProps['options']>([
  {
    value: '嵌入式塑壳RJBS-1(开孔153*77)',
    label: '嵌入式塑壳RJBS-1(开孔153*77)'
  },
  {
    value: '嵌入式塑壳RJBS-1(开孔180*230)',
    label: '嵌入式塑壳RJBS-1(开孔180*230)'
  },
  {
    value: '壁挂式铁壳温控器',
    label: '壁挂式铁壳温控器'
  }
])

const bottomProtectOptions = ref<SelectProps['options']>([
  {
    value: '无防护',
    label: '无防护'
  },
  {
    value: '底板',
    label: '底板'
  },
  {
    value: '底座',
    label: '底座'
  }
])

const dryerNumberOptions = ref<SelectProps['options']>([
  {
    value: '2',
    label: '2'
  },
  {
    value: '4',
    label: '4'
  },
  {
    value: '6',
    label: '6'
  }
])

const installTypeOptions = ref<SelectProps['options']>([
  {
    value: '顶吹',
    label: '顶吹'
  },
  {
    value: '侧吹',
    label: '侧吹'
  }
])

const formRef = ref()
const formSelectState = reactive({
  clampWidth: undefined,
  bottomWidth: undefined,
  lowHeight: undefined,
  protectGrade: undefined,
  material: undefined,
  holeType: undefined,
  bottomProtect: undefined,
  capacity: '',
  dryer: '',
  installType: undefined
})
const formResultState = reactive({
  shellWidth: '',
  shellHeight: '',
  shellWeight: '',
  protectGrade: '',
  shellMaterial: '',
  temperature: '',
  bottomProtect: '',
  fanModel: '',
  fanNum: ''
})
export default defineComponent({

  setup () {
    return {
      formRef,
      formSelectState,
      formState: formResultState,
      protectGradeOptions,
      materialOptions,
      holeTypeOptions,
      bottomProtectOptions,
      dryerNumberOptions,
      installTypeOptions,
      handleClampChange,
      handleBottomChange,
      handleLowChange,
      handleProtectGradleChange,
      handleMaterialChange,
      handleHoleChange,
      handleBottomProtectChange,
      handleCapacityChange,
      handleFanNumChange,
      handleInstallTypeChange
    }
  }
})

function handleClampChange (value: any) {
  const result = parseInt(value) + 200
  formResultState.shellWidth = result + 'mm'
}

function handleBottomChange (value: any) {
  const result = parseInt(value) + 450
  formResultState.shellWeight = result + 'mm'
}

function handleLowChange (value: any) {
  const result = parseInt(value)
  formResultState.shellHeight = result + 'mm'
}

function handleProtectGradleChange (value: any) {
  formResultState.protectGrade = value
}

function handleMaterialChange (value: any) {
  formResultState.shellMaterial = value
}

function handleHoleChange (value: any) {
  formResultState.temperature = value
}

function handleBottomProtectChange (value: any) {
  formResultState.bottomProtect = value
}

function handleCapacityChange (value: any) {
  genFjModel()
}

function handleFanNumChange (value: any) {
  formResultState.fanNum = value
}

function handleInstallTypeChange (value: any) {
  genFjModel()
}

function calcFjWidth () {
  const clampValue = formSelectState.clampWidth
  if (clampValue == null || clampValue === '') {
    return 0
  }
  const clamp = parseInt(clampValue)
  const fjNum = parseInt(formSelectState.dryer)
  let fjLength = clamp
  if (fjNum === 4) {
    if (clamp > 150) {
      fjLength = (clamp - 150) / 2
    }
  } else if (fjNum === 6) {
    if (clamp > (105 * 2 + 100)) {
      fjLength = (clamp - (105 * 2 + 100)) / 3
    }
  }
  return fjLength
}

function calcFjWheelWidth () {
  const value = formSelectState.capacity
  const cap = parseInt(value)
  let wheelWidth = 0
  if (cap < 600) {
    wheelWidth = 90
  } else if (cap < 1250) {
    wheelWidth = 110
  } else if (cap < 1600) {
    wheelWidth = 120
  } else {
    wheelWidth = 150
  }
  return wheelWidth
}

function genFjModel () {
  const fjLength = calcFjWidth()
  const wheel = calcFjWheelWidth()
  let type = 'D'
  const installType = formSelectState.installType === '' ? '顶吹' : formSelectState.installType
  if (installType === '顶吹') {
    type = 'D'
  } else {
    type = ''
  }
  if (fjLength > 0 && wheel > 0) {
    formResultState.fanModel = 'GFD' + type + ' ' + fjLength.toString() + '-' + wheel.toString()
  }
}

