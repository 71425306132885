import axios from 'axios'

const Api = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  baseURL: process.env.VUE_APP_BASE_API,
  // 超时
  timeout: 100000
})
Api.interceptors.response.use(
  response => {
    const resp = response.data
    resp.success = response.status === 200
    return resp
  },
  error => {
    return Promise.reject(error)
  }
)
export default Api
