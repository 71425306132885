
import { defineComponent, ref } from 'vue'
import { EnvironmentOutlined, PhoneOutlined, MailOutlined } from '@ant-design/icons-vue'
import router from '@/router'
import { bus } from './utils/Mitt'

const navTitles = ref<string[]>(['首页', '计算器', '产品选型', '关于我们'])
const navRouters = ['/', '/calc', '/product', '/about']
const navTitle = ref<string>('首页')
const isDetail = ref<boolean>(false)
export default defineComponent({
  components: {
    EnvironmentOutlined,
    PhoneOutlined,
    MailOutlined
  },
  mounted () {
    bus.on('detail', () => {
      console.log('eventBus')
      isDetail.value = true
      navTitle.value = '产品详情'
    })
  },
  setup () {
    return {
      navTitles,
      navTitle,
      onMenuSelect,
      isDetail,
      selectedKeys: ref<string[]>(['0']),
      logo: require('./assets/logo.png')
    }
  }

})

function onMenuSelect (item: any) {
  const index = Number(item.key)
  navTitle.value = navTitles.value[index]
  router.push(navRouters[index])
}
